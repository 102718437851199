import React from 'react';
import { IChatMessage, IChatSetting } from '../types/apiPayloads';
import { AxiosError } from 'axios';
import { chunk, orderBy } from 'lodash';
import { ReactComponent as ChatGPTIcon } from '../icons/chatGPT.svg';
import { ReactComponent as ClaudeIcon } from '../icons/claude.svg';
import { ReactComponent as DeepSeekIcon } from '../icons/deepseek.svg';

export const inputToMessage = (
  input: string,
  chatId: string | undefined,
  chatSettingsId: string,
): IChatMessage => {
  const newMessage: IChatMessage = { text: input };
  if (chatId) {
    newMessage.chat_id = chatId;
  }
  if (chatSettingsId) {
    newMessage.chat_settings_id = chatSettingsId;
  }
  return newMessage;
};

export const respectNewLines = (text: string) => {
  if (!text) return '';
  return text.split('\n').map((item, index) => (
    <span key={index}>
      {item}
      <br />
    </span>
  ));
};

// Utility function for error handling
export const handleAxiosError = (error: unknown): never => {
  let errorMessage = 'A server error occurred';
  if (error instanceof AxiosError) {
    if (error.response?.status === 401) {
      errorMessage = 'Unauthorized';
      localStorage.removeItem('jwtToken');
      window.location.href = '/login';
    } else {
      errorMessage = error.response?.data?.error || errorMessage;
    }
  }
  throw new Error(errorMessage);
};

export const splitIntoGroups = (
  chatSettings: IChatSetting[],
  groupSize: number,
): IChatSetting[][] => {
  return chunk(
    orderBy(chatSettings, ['model', 'updated_at'], ['asc', 'desc']),
    groupSize,
  );
};

export const chatsCountLabel = (count: number): string => {
  return count === 1 ? '1 chat' : `${count} chats`;
};

export const getModelIcon = (model: string): React.JSX.Element | null => {
  switch (model) {
    case 'openai-chatgpt-4o':
      return <ChatGPTIcon />;
    case 'claude-sonnet-3.5':
      return <ClaudeIcon />;
    case 'deepseek-r1':
      return <DeepSeekIcon />;
    default:
      return null;
  }
};
