import React, { FormEvent, useState } from 'react';
import { useChatSettings } from '../../contexts/ChatSettingsContext';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { IPrompt } from '../../types/apiPayloads';

const MODEL_NAMES: { [key: string]: string } = {
  ChatGPT: 'openai-chatgpt-4o',
  Claude: 'claude-sonnet-3.5',
  DeepSeek: 'deepseek-r1',
};

const PromptForm: React.FC = () => {
  const {
    handleCancelEditing,
    handleUpdateChatSettings,
    handleCreateChatSettings,
    editableSetting,
  } = useChatSettings();
  const [prompt, setPrompt] = useState<IPrompt>({
    title: editableSetting?.title || '',
    system_prompt: editableSetting?.system_prompt || '',
    model_name: editableSetting?.model_name || MODEL_NAMES.ChatGPT,
  });

  const handlePromptTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrompt({
      ...prompt,
      title: e.target.value,
    });
  };

  const handlePromptContentChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setPrompt({
      ...prompt,
      system_prompt: e.target.value,
    });
  };

  const handleModelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPrompt({
      ...prompt,
      model_name: e.target.value,
    });
  };

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (editableSetting) {
      handleUpdateChatSettings(prompt);
    } else {
      handleCreateChatSettings(prompt);
    }
  };

  return (
    <Row className='mt-4'>
      <Col>
        <Form onSubmit={handleFormSubmit}>
          <Form.Group className='mb-3' controlId='promptTitle'>
            <Form.Label>Model</Form.Label>
            <Form.Select onChange={handleModelChange}>
              {Object.keys(MODEL_NAMES).map(model => (
                <option
                  key={model}
                  value={MODEL_NAMES[model]}
                  selected={prompt.model_name === MODEL_NAMES[model]}
                >
                  {model}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className='mb-3' controlId='promptTitle'>
            <Form.Label>Title</Form.Label>
            <Form.Control
              type='text'
              required
              value={prompt.title}
              onChange={handlePromptTitleChange}
            />
          </Form.Group>
          <Form.Group className='mb-3' controlId='promptText'>
            <Form.Label>Text</Form.Label>
            <Form.Control
              as='textarea'
              rows={7}
              required
              value={prompt.system_prompt}
              onChange={handlePromptContentChange}
            />
          </Form.Group>
          <Button
            style={{ float: 'right' }}
            variant='primary'
            className='bt'
            type='submit'
          >
            Submit
          </Button>
          <Button
            style={{ float: 'right', marginRight: '10px' }}
            variant='secondary'
            className='bt'
            onClick={handleCancelEditing}
          >
            Cancel
          </Button>
        </Form>
      </Col>
    </Row>
  );
};

export default PromptForm;
