import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useChats } from '../../contexts/ChatsContext';
import {
  ButtonGroup,
  ButtonToolbar,
  Form,
  ToggleButton,
} from 'react-bootstrap';
import { useChatSettings } from '../../contexts/ChatSettingsContext';
import { getModelIcon, splitIntoGroups } from '../../utils/helpers';
import { IChatSetting } from '../../types/apiPayloads';

const ChatForm = () => {
  const [chatInput, setChatInput] = useState<string>('');
  const [settingId, setSettingId] = useState<string>('');
  const [maximized, setMaximized] = useState<boolean>(false);
  const { loadingState, handleSendMessage, activeChatId } = useChats();
  const { chatSettings } = useChatSettings();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const isTouchDevice = (): boolean =>
    'ontouchstart' in window || navigator.maxTouchPoints > 0;

  const chatSettingsInChunks = useMemo((): IChatSetting[][] => {
    return splitIntoGroups(chatSettings, 4);
  }, [chatSettings]);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [activeChatId, maximized]);

  const onEnterPress = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (isTouchDevice()) {
      return;
    }
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onFormSubmit();
    }
  };

  const onFormSubmit = () => {
    if (!chatInput.length || loadingState) return;

    clearChatInput();
    sendMessageToApi();
  };

  const onSendButtonClick = () => {
    onFormSubmit();
  };

  const onMaxButtonClick = () => {
    setMaximized(oldValue => !oldValue);
  };

  const clearChatInput = () => {
    setChatInput('');
  };

  const sendMessageToApi = () => {
    if (!chatInput.length || loadingState) return;

    handleSendMessage(chatInput, settingId);
  };

  const unClick = (id: string | undefined) => {
    if (id === settingId) {
      setSettingId('');
    }
  };

  return (
    <Form>
      {activeChatId === 'newChat' && (
        <>
          <Form.Group className='d-none d-lg-block'>
            <ButtonToolbar style={{ marginBottom: '10px' }}>
              {chatSettingsInChunks.map(chunk => {
                return (
                  <ButtonGroup
                    key={chunk[0].id}
                    style={{ width: '100%', marginBottom: '5px' }}
                    onClick={e => unClick((e.target as HTMLInputElement).value)}
                  >
                    {chunk.map(({ id, title, model_name }) => (
                      <ToggleButton
                        id={id}
                        key={id}
                        type='radio'
                        variant='light'
                        name='radio'
                        value={id}
                        checked={settingId === id}
                        onChange={e => setSettingId(e.currentTarget.value)}
                      >
                        {getModelIcon(model_name)}
                        <span style={{ marginRight: '10px' }} />
                        {title}
                      </ToggleButton>
                    ))}
                  </ButtonGroup>
                );
              })}
            </ButtonToolbar>
          </Form.Group>
          <Form.Group className='d-lg-none'>
            <Form.Select
              value={settingId}
              className='mb-2'
              onChange={e => setSettingId(e.currentTarget.value)}
            >
              <option value=''>Select a setting</option>
              {chatSettings.map(({ id, title }) => (
                <option key={id} value={id}>
                  {title}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </>
      )}
      <Form.Group style={{ position: 'relative' }}>
        <Form.Control
          as='textarea'
          rows={1}
          placeholder='Type in message here...'
          className={`${maximized ? 'chat-textarea-maximized' : 'chat-textarea'} `}
          onKeyDown={onEnterPress}
          value={chatInput}
          onChange={event => setChatInput(event.target.value)}
          ref={textAreaRef}
        />
        <span
          className='d-none d-lg-block material-symbols-outlined'
          style={{
            position: 'absolute',
            right: '0%',
            top: '0%',
            cursor: 'pointer',
            color: '#9a9a9e',
            border: '1px solid',
            borderRadius: '5px',
            padding: '5px',
          }}
          onClick={onMaxButtonClick}
        >
          {`${maximized ? 'vertical_align_bottom' : 'vertical_align_top'}`}
        </span>
        <span
          onClick={onSendButtonClick}
          style={{ position: 'absolute', right: '2%', top: '50%' }}
          className='d-lg-none floating-button material-symbols-outlined'
        >
          arrow_upward
        </span>
      </Form.Group>
    </Form>
  );
};

export default ChatForm;
