import React, { useRef, useState } from 'react';
import { Badge, Button } from 'react-bootstrap';
import clipboardCopy from 'clipboard-copy';
import MarkdownCopy from '../shared/MarkdownCopy';

const Message: React.FC<{
  text: string;
  userInput: boolean;
  username: string;
  reasoning?: string;
}> = ({ text, username, userInput, reasoning }) => {
  const [copied, setCopied] = useState<boolean>(false);

  const markdownRef = useRef<HTMLDivElement>(null);

  const handleCopyWholeMessage = () => {
    setCopied(true);
    clipboardCopy(text).catch(err => {
      console.error('Failed to copy markdown: ', err);
      setCopied(false);
    });
  };

  return (
    <div className='my-2'>
      <Badge pill bg={userInput ? 'info' : 'primary'}>
        {username}
      </Badge>
      <Button
        style={{ float: 'right', color: copied ? '#66ae63' : '#9a9a9e' }}
        variant='link'
        size='sm'
        onClick={handleCopyWholeMessage}
      >
        <span className='material-symbols-outlined'>content_copy</span>
      </Button>
      {reasoning && (
        <div className='p-5 m-7 bg-blue-300 border border-gray-300 rounded-lg' ref={markdownRef}>
          <MarkdownCopy markdown={reasoning} />
        </div>
      )}
      <div className='p-2' ref={markdownRef}>
        <MarkdownCopy markdown={text} />
      </div>
    </div>
  );
};
export default Message;
